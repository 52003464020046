
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"); 

.App {
  text-align: center;
}

body {
  background-color: black;
  overflow-x: hidden;
}